import { useQuery } from '@tanstack/react-query';
import { ReactQueryKeys } from './queryKeys';
import { ProductCode } from '../types/proceq';
import { getBinaries } from '../api/filesService';

export const useProductBinaries = (productCode: ProductCode) =>
    useQuery({
        queryKey: ReactQueryKeys.appProductBinaries(productCode),
        queryFn: () => getBinaries(productCode),
        refetchOnWindowFocus: false,
    });
