import React, { useCallback, useRef, useState } from 'react';
import { Form, Input, Select } from 'antd';
import { FormItem, useFormCheckErrors } from 'tds-common-fe';
import { Rule } from 'antd/lib/form';
import styles from './ActivateLicenseModal.styl';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import FormattedMessage from '../../../localization/FormatMessage';
import { ReactComponent as CloseIcon } from '../../../images/iconClose.svg';
import StyledModal from '../../shared/StyledModal';
import FreeTrialModal, { LicenseActionState } from '../../License/FreeTrialModal';
import { LicenseProduct, LicenseProductNameMap, UserLicenseErrorCode } from '../../../types/license';
import { useCurrentUserEmail } from '../../../hooks/useCurrentUser';
import { incompleteUUIDRegex, uuidRegex } from '../../../utils/regex';
import { formatUUID } from '../../../utils/uuidUtils';
import { activateLicense, getActiveApps } from '../../../api/appService';
import { handleError } from '../../../api/error';
import { getLicenseList } from '../../../api/userService';
import { FormatIDs } from '../../../types';
import analytics from '../../../analytics/firebaseAnalytics';
import { SubscriptionAction } from '../../../analytics/analyticsConstants';

const uuidHyphenPositions = [8, 13, 18, 23];
const licenseKeyMaxLength = 36;

interface FieldsType {
    licenseKey: string;
    product?: string;
}

class Item extends FormItem<FieldsType> {}

type ActivateLicenseModalProps = {
    onClose: () => void;
    product: LicenseProduct;
    licenseKey?: string;
};

const rules: { [K in keyof FieldsType]?: Rule[] } = {
    licenseKey: [
        {
            required: true,
            message: <FormattedMessage id="License.Field.LicenseKey.Error.Empty" />,
            max: 36,
        },
        {
            pattern: uuidRegex,
            message: <FormattedMessage id="License.Field.LicenseKey.Error.Incomplete" />,
        },
    ],
    product: [
        {
            required: true,
            message: <FormattedMessage id="License.Field.LicenseKey.Error.ProductEmpty" />,
        },
    ],
};

type LicenseErrorMessageProps = {
    onClickSupport: () => void;
    text: FormatIDs;
};

const LicenseErrorMessage: React.FunctionComponent<LicenseErrorMessageProps> = (props) => {
    const { onClickSupport, text } = props;
    const SupportLink = () => {
        return (
            <AnalyticsButton type="link" onClick={onClickSupport}>
                <FormattedMessage id="License.Field.LicenseKey.SupportLink" />
            </AnalyticsButton>
        );
    };
    return (
        <span className={styles.error_message}>
            <FormattedMessage id={text} values={{ supportLink: <SupportLink /> }} />
        </span>
    );
};

const ActivateLicenseModal: React.FunctionComponent<ActivateLicenseModalProps> = (props) => {
    const { onClose, product, licenseKey } = props;
    const [form] = Form.useForm();
    const email = useCurrentUserEmail();

    const currentKeypress = useRef('');
    const prevLicenseKeyValue = useRef('');

    const { setFieldsValue, validateFields } = form;

    const { checkErrors } = useFormCheckErrors(form, ['licenseKey']);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showContactSales, setShowContactSales] = useState(false);
    const [isLoadingLicense, setIsLoadingLicense] = useState(false);

    const onKeyDown = useCallback((event: React.KeyboardEvent) => {
        currentKeypress.current = event.key;
    }, []);

    const onLicenseInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            let newValue = formatUUID(value.trim());

            if (incompleteUUIDRegex.test(newValue)) {
                if (uuidHyphenPositions.includes(newValue.length)) {
                    const pressedKey = String(currentKeypress.current).toLowerCase();
                    if (!(pressedKey === 'backspace' || pressedKey === 'delete')) {
                        newValue = `${newValue}-`;
                    }
                }
            } else {
                newValue = prevLicenseKeyValue.current;
            }

            setFieldsValue({ licenseKey: newValue.toUpperCase() });
            prevLicenseKeyValue.current = newValue;
            if (value !== newValue) {
                validateFields(['licenseKey']);
            }
        },
        [setFieldsValue, validateFields]
    );

    const handleActivation = useCallback(async () => {
        const licenseKey = form.getFieldValue('licenseKey');
        const product = form.getFieldValue('product');
        setIsLoadingLicense(true);
        try {
            await activateLicense(product, licenseKey);
            await getLicenseList();
            await getActiveApps(); // get active apps to update eligibleFreeTrial
            onClose();
        } catch (error: any) {
            setShowConfirmation(false);
            const errorCode = error.response?.data?.code;
            switch (errorCode) {
                case UserLicenseErrorCode.LicenseKeyAlreadyInUse:
                    form.setFields([
                        {
                            name: 'licenseKey',
                            errors: [
                                (
                                    <LicenseErrorMessage
                                        text="License.Field.LicenseKey.Error.Activated"
                                        onClickSupport={() => setShowContactSales(true)}
                                    />
                                ) as any,
                            ],
                        },
                    ]);
                    break;
                case UserLicenseErrorCode.LicenseKeyInvalid:
                    form.setFields([
                        {
                            name: 'licenseKey',
                            errors: [
                                (
                                    <LicenseErrorMessage
                                        text="License.Field.LicenseKey.Error.Invalid"
                                        onClickSupport={() => setShowContactSales(true)}
                                    />
                                ) as any,
                            ],
                        },
                    ]);
                    break;
                case UserLicenseErrorCode.LicenseKeyActivationDeviceIDRequired:
                    form.setFields([
                        {
                            name: 'licenseKey',
                            errors: [
                                (
                                    <LicenseErrorMessage
                                        text="License.Field.LicenseKey.Error.DeviceID"
                                        onClickSupport={() => setShowContactSales(true)}
                                    />
                                ) as any,
                            ],
                        },
                    ]);
                    break;
                default:
                    handleError(error);
            }
        } finally {
            setIsLoadingLicense(false);
            analytics.logSubscriptionEvent(SubscriptionAction.activateConfirm, product);
        }
    }, [form, onClose]);

    const handleCloseConfirmationModal = () => {
        setShowConfirmation(false);
        analytics.logSubscriptionEvent(SubscriptionAction.activateCancel, product);
    };

    const confirmationModal = (
        <StyledModal
            open={showConfirmation}
            className={styles.modal_container}
            title={<FormattedMessage id="License.ActivationPopup.Title" />}
            getContainer={() => document.querySelector('#root') || document.body}
            closeIcon={<CloseIcon />}
            destroyOnClose
            onCancel={handleCloseConfirmationModal}
            centered
            footer={
                <>
                    <AnalyticsButton type="primary" ghost onClick={handleCloseConfirmationModal}>
                        <FormattedMessage id="App.Cancel" />
                    </AnalyticsButton>
                    <AnalyticsButton
                        className={styles.confirm_license_button}
                        type="primary"
                        onClick={handleActivation}
                        loading={isLoadingLicense}
                    >
                        <FormattedMessage id="App.Confirm" />
                    </AnalyticsButton>
                </>
            }
        >
            <>
                <FormattedMessage id="License.ActivationPopup.Content" />
                <b>{email}</b>
                <FormattedMessage id="License.ActivationPopup.Action" />
            </>
        </StyledModal>
    );

    return (
        <>
            <StyledModal
                className={styles.modal_container}
                title={<FormattedMessage id="License.Field.Title" />}
                getContainer={() => document.querySelector('#root') || document.body}
                open={!showConfirmation && !showContactSales}
                onCancel={() => {
                    analytics.logSubscriptionEvent(SubscriptionAction.activateClose, product);
                    onClose();
                }}
                footer={null}
                closeIcon={<CloseIcon />}
                destroyOnClose
                centered
            >
                <div className={styles.export_options_wrapper}>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={() => {
                            setShowConfirmation(true);
                            analytics.logSubscriptionEvent(SubscriptionAction.activate, product);
                        }}
                    >
                        <div>
                            <Item
                                label={<FormattedMessage id="License.Subscription.Activate.SelectProduct" />}
                                className={styles.form_item}
                                name="product"
                                rules={rules.product}
                                required={false}
                                initialValue={product}
                            >
                                <Select className={styles.item_select} value={product}>
                                    {Object.values(LicenseProduct)
                                        .filter((item) => item !== LicenseProduct.inspect)
                                        .map((item) => (
                                            <Select.Option key={item} value={item}>
                                                {LicenseProductNameMap[item]}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Item>
                        </div>
                        <div>
                            <Item
                                label={<FormattedMessage id="License.Subscription.Activate.LicenseKey" />}
                                className={styles.form_item}
                                name="licenseKey"
                                rules={rules.licenseKey}
                                required={false}
                                initialValue={licenseKey ?? ''}
                                shouldUpdate
                                extra={
                                    <FormattedMessage
                                        id="License.Field.LicenseKey.description"
                                        values={{ count: 32 }}
                                    />
                                }
                            >
                                <Input
                                    placeholder="XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"
                                    autoComplete="off"
                                    onChange={onLicenseInputChange}
                                    onKeyDown={onKeyDown}
                                    maxLength={licenseKeyMaxLength}
                                />
                            </Item>
                        </div>
                        <div>
                            <Item shouldUpdate className={styles.form_item_last}>
                                {() => (
                                    <AnalyticsButton
                                        type="primary"
                                        htmlType="submit"
                                        className={styles.activate_license_button}
                                        disabled={checkErrors()}
                                    >
                                        <FormattedMessage id="License.Activate" />
                                    </AnalyticsButton>
                                )}
                            </Item>
                        </div>
                    </Form>
                </div>
            </StyledModal>
            {confirmationModal}
            <FreeTrialModal
                open={showContactSales}
                licenseActionState={LicenseActionState.contactSales}
                onClose={() => {
                    setShowContactSales(false);
                    onClose();
                }}
                productCode={product}
            />
        </>
    );
};

export default ActivateLicenseModal;
