import config from '../config';
import { ProductCode } from '../types/proceq';
import { BinaryFile } from '../types/binaryFile';

const PUBLIC_FILES_ROUTE = `${config.EAGLE_CLOUD_DOMAIN}/files`;

// use fetch directly as it's not a backend API, no need to check for preflight OPTIONS
export const getBinaries = async (productCode: ProductCode) => {
    const url = `${PUBLIC_FILES_ROUTE}/${productCode.toLowerCase()}/version.json`;
    const versionInfo = await fetch(url, { cache: 'no-cache' });
    return (await versionInfo.json()) as BinaryFile;
};

export const getPublicFile = async (fileName: string, productCode: ProductCode) =>
    fetch(`${PUBLIC_FILES_ROUTE}/${productCode.toLowerCase()}/${fileName}`);
