import { useQuery } from '@tanstack/react-query';
import * as userService from '../api/userService';
import { getEagleIDProfile, getUserDeleteAccount } from '../api/userService';
import { APIError } from '../api/error';
import { APIResponse } from '../types/api';
import { UserProfileInfo } from '../types/profile';
import { ReactQueryKeys } from './queryKeys';

export const useLicenseList = () =>
    useQuery({
        queryKey: ReactQueryKeys.license,
        queryFn: userService.getLicenseList,
        refetchOnWindowFocus: false,
    });

export const useEagleIDProfile = (userID: number | undefined, errorHandler: (error: any) => void) =>
    useQuery<APIResponse<UserProfileInfo>, APIError>({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: ReactQueryKeys.userProfile(userID!),
        queryFn: () => getEagleIDProfile(userID!.toString(), errorHandler),
        enabled: !!userID,
    });

export const useProceqUser = (userID: number | undefined, isCurrentUser?: boolean) =>
    useQuery({
        queryKey: ReactQueryKeys.proceqUser(userID!, isCurrentUser),
        queryFn: () => userService.getProceqUser(userID!, isCurrentUser),
        enabled: !!userID,
        refetchOnWindowFocus: false,
    });

export const useUserDeleteAccount = () =>
    useQuery({
        queryKey: ReactQueryKeys.accountDeletion(),
        queryFn: getUserDeleteAccount,
        refetchOnWindowFocus: false,
    });
