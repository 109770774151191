import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import StyledModal from '../shared/StyledModal';
import FormattedMessage from '../../localization/FormatMessage';
import TierFeatures from '../Profile/UserLicense/TierFeatures';
import { ProductCode } from '../../types/proceq';
import { LicenseProduct, UserLicenseInfo } from '../../types/license';
import { usePlanTiers } from '../../hooks/useConfig';
import FreeTrialModal, { LicenseActionState } from './FreeTrialModal';
import analytics from '../../analytics/firebaseAnalytics';
import { SubscriptionAction } from '../../analytics/analyticsConstants';

interface UpgradeModalProps {
    open: boolean;
    onCancel: () => void;
    product: ProductCode | LicenseProduct;
    license: Partial<UserLicenseInfo>;
    navigateToDashboardOnClose?: boolean; // if user closes all modals, navigate back to dashboard
}

// corresponds with values in stylus file
const MAX_TIER_CONTAINER_WIDTH = 290;
const TIER_GAP = 32;

const UpgradeModal: React.FunctionComponent<UpgradeModalProps> = (props) => {
    const { open, onCancel, product, license, navigateToDashboardOnClose } = props;
    const tiers = usePlanTiers(product);
    const history = useHistory();
    const [showContactForm, setShowContactForm] = useState<string | undefined>();
    const navigateToDashboard = () => {
        if (navigateToDashboardOnClose) {
            history.push('/');
        }
    };
    const maxWidth = MAX_TIER_CONTAINER_WIDTH * (tiers?.length ?? 0) + TIER_GAP * ((tiers?.length ?? 0) - 1);

    return tiers ? (
        <>
            <StyledModal
                title={<FormattedMessage id="Profile.Subscriptions.UpgradeGPRInsights" />}
                open={open}
                width={`min(80%, ${maxWidth}px)`}
                footer={null}
                onCancel={() => {
                    analytics.logSubscriptionEvent(SubscriptionAction.upgradeClose, product);
                    onCancel();
                    navigateToDashboard();
                }}
            >
                <TierFeatures
                    isModalDisplay
                    product={product}
                    tiers={tiers}
                    currentTier={license?.tier}
                    isFreeTrial={false}
                    onContactSales={(tier: string) => {
                        setShowContactForm(tier);
                        onCancel();
                    }}
                    isCurrentLicenseExpired={license?.active === false}
                />
            </StyledModal>
            <FreeTrialModal
                productCode={product}
                tier={showContactForm}
                licenseActionState={LicenseActionState.contactSales}
                onClose={() => {
                    setShowContactForm(undefined);
                    navigateToDashboard();
                }}
                showFreeLicense={false}
                open={!!showContactForm}
            />
        </>
    ) : null;
};

export default UpgradeModal;
