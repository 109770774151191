import { browserUtils } from 'tds-common-fe';
import React, { useState } from 'react';
import { Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import StyledModal from '../../shared/StyledModal';
import FormattedMessage from '../../../localization/FormatMessage';
import styles from './LaunchAppModal.styl';
import { ProductCode } from '../../../types/proceq';
import analytics from '../../../analytics/firebaseAnalytics';
import config from '../../../config';
import { useProductBinaries } from '../../../queries/productQueries';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import appIcon from '../../../images/appIconGPRMountedUpload.png';
import useCurrentUserID from '../../../hooks/useCurrentUser';
import { downloadBinaryFile } from '../../../utils/fileUtils';

interface LaunchAppModalProps {
    open: boolean;
    onClose: () => void;
    measurementID: string;
    product: ProductCode;
}

const LaunchAppModal: React.FunctionComponent<LaunchAppModalProps> = ({ open, onClose, measurementID, product }) => {
    const { data, isSuccess } = useProductBinaries(product);
    const formatMessage = useFormatMessage();
    const userID = useCurrentUserID();
    const [isDownloading, setIsDownloading] = useState(false);

    const handleLaunchApp = () => {
        analytics.logLaunchApp(product, 'launch_app');
        window.open(`${config.GM_URL_SCHEME}://measurement/${measurementID}?userId=${userID}`, '_self');
        onClose();
    };

    const handleDownload = () => {
        analytics.logLaunchApp(product, 'download_now');
        setIsDownloading(true);
        const fileName =
            browserUtils.getOSName(true) === 'macos' ? data?.binaries?.macos_universal : data?.binaries?.['win_x86-64'];
        if (fileName) {
            downloadBinaryFile({ formatMessage, product, fileName, onComplete: () => setIsDownloading(false) });
        }
    };

    return (
        <StyledModal open={open} footer={null} onCancel={onClose}>
            <div className={styles.body_container}>
                <div>
                    <img src={appIcon} alt="appIcon" className={styles.logo} />
                </div>
                <div className={styles.subtitle3}>
                    <FormattedMessage id={'LaunchApp.Title'} values={{ appName: 'GM' }} />
                </div>
                <div>
                    <FormattedMessage id={'LaunchApp.Body'} values={{ appName: 'GM' }} />
                </div>
                <Button className={styles.launch_button} type="primary" onClick={handleLaunchApp}>
                    <FormattedMessage id="Import.LaunchApp" />
                </Button>
                {isSuccess && (
                    <div className={styles.caption}>
                        <FormattedMessage id="Import.FooterText" values={{ appName: 'GM' }} />
                        <Button
                            disabled={isDownloading}
                            className={styles.download_button}
                            type="link"
                            onClick={handleDownload}
                        >
                            <FormattedMessage id={isDownloading ? 'LaunchApp.Downloading' : 'LaunchApp.DownloadNow'} />
                            {isDownloading && (
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 12, marginLeft: 5 }} spin />} />
                            )}
                        </Button>
                    </div>
                )}
            </div>
        </StyledModal>
    );
};

export default LaunchAppModal;
